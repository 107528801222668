import React from "react"
import { Link } from "gatsby"

import { thumbPress } from "../utils/image"
import { pressUrl } from "../utils/url"
import t from "../utils/translate"

import styles from "./presslist.module.css"

const PressList = ({ press, locale }) => (
  <div className={styles.galleryContainer}>
    {press && press.map((item, index) => (
      item.otherlink ? (
        <a href={item.otherlink} key={index} target="_blank" rel="noreferrer">
          <img src={thumbPress(item.image, 900)} alt={item.title} />
          <h3 className={styles.h3Gallery}>{t(item.title, locale)}</h3>
      </a>
        ) : (
        <Link key={index} to={item.otherlink ? item.otherlink : pressUrl(locale, item)}>
          <img src={thumbPress(item.image, 900)} alt={item.title} />
          <h3 className={styles.h3Gallery}>{t(item.title, locale)}</h3>
        </Link>
        )

    ))}
  </div>
)

export default PressList
