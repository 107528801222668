import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PressList from "../components/presslist"
import t from "../utils/translate"

import styles from "./presslist.module.css"

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...CommonPage
    }
    allSanityPress(sort: { fields: slug___current, order: DESC }) {
      nodes {
        id
        title {
          ...Locales
        }
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
        otherlink
      }
    }
  }
`

const PressListTemplate = ({
  data: { sanityPage: page, allSanityPress },
  pageContext: { locale },
}) => {
  return (
    <Layout locale={locale}>
      <Seo locale={locale} seofield={page.seo}></Seo>
      <div className={styles.pressContainer}>
        <h1 className={styles.h1}>{t(page.title, locale)}</h1>
          <PressList locale={locale} press={allSanityPress.nodes} />
      </div>
    </Layout>
  )
}

export default PressListTemplate
