/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { socialImage } from "../utils/image"
import t from "../utils/translate"

function SEO({ meta, locale, seofield }) {
  return (
    <Helmet
      htmlAttributes={{
        locale,
      }}
      title={seofield ? t(seofield.title, locale) : ""}
      titleTemplate={`%s | Nicole Cieri Architects`}
      meta={[
        {
          name: `description`,
          content: seofield && t(seofield.description, locale),
        },
        {
          property: `og:title`,
          content: seofield && t(seofield.socialTitle, locale),
        },
        {
          property: `og:description`,
          content: seofield && t(seofield.socialDescription, locale),
        },
        {
          property: `og:image`,
          content:
            seofield &&
            seofield.socialShareImage &&
            socialImage(seofield.socialShareImage),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "PLUGGED AUTHOR",
        },
        {
          name: `twitter:title`,
          content: seofield && t(seofield.socialTitle, locale),
        },
        {
          name: `twitter:description`,
          content: seofield && t(seofield.socialDescription, locale),
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
}

SEO.propTypes = {
  locale: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
