import locales from "../config/locales"

const defaultLocale = locales.find(locale => locale.path === "").code

export default (field, locale) => {
  let translation
  if (!field) {
    translation = ""
  } else {
    translation = field[locale] || field[defaultLocale]
  }
  return translation
}

const convert = keys => `[${keys.map(key => `"${key}"`).join(", ")}]`
export const translations = keys => `
  sanityTranslations(translations: { elemMatch: { key: {in: [${convert(
    keys
  )}]} }}) {
    translations {
      en
      it
      key
    }
  }
`
