import imageUrlBuilder from "@sanity/image-url"

import config from "../config/sanity"

const builder = imageUrlBuilder(config)

export const thumb = image => builder.image(image).size(1200, 720).url()
export const thumbPress = image => builder.image(image).size(720, 900).url()
export const home = (image, size) => builder.image(image).width(size).url()
export const featured = (image, size) => builder.image(image).width(size).url()
export const list = (image, size) => builder.image(image).width(size).url()
export const socialImage = image => builder.image(image).size(1200, 630).url()
